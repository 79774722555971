export class User {
    [x: string]: any;

    build(data: object) {
        this.userId = data['userId'];
        this.firstName = data['firstName'];
        this.emailId = data['emailId'];
        this.lastName = data['lastName'];
        this.isAdmin =  data['admin'];
        this.types = data['types'];
        this.phone = data['phone'];
        this.parentPhone = data['parentPhone'];
        this.status =  data['status'];
        this.dateOfBirth = data['dateOfBirth'];
        this.profilePicUrl = data['profilePictureUrl'];
        this.forceResetPassword = data['forceResetPassword'];
        this.fullName = this.firstName +" " +this.lastName;
        this.schoolName = data['schoolName'];
    
    }

    userId: number;
    emailId: string;
    firstName: string;
    lastName: string;
    phone: string;
    parentPhone: string;
    password: string;
    isAdmin: boolean;
    status: string;
    types: object;
    dateOfBirth: any;
    profilePicUrl: string;
    forceResetPassword: boolean;
    fullName:string;
    schoolName: string;
    //userToken:string;
}