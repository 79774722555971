// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://api.sunilasalchemy.com:2009/sunilasalchemy',
  apiUrl: 'https://api.sunilasalchemy.com:2083/sunilasalchemy',

  // apiUrl: 'http://localhost:2009/sunilasalchemy',
  //apiUrl: 'http://192.168.1.6:2009/sunilasalchemy',
  //apiUrl: 'http://10.0.2.2:2009/sunilasalchemy',
  //apiUrl: 'https://api-dev.jivrom.com:2009/sunilasalchemy',
  
  //apiUrl: 'http://api-dev.jivrom.com:2009/sunilasalchemy',

  // firebaseConfig : {
  //   apiKey: "AIzaSyC-UWqscnzQGQZ0q5pNe3DrlDR-BneyuWM",
  //   authDomain: "sunilas-alchemy.firebaseapp.com",
  //   databaseURL: "https://sunilasalchemy-cf09d.firebaseio.com",
  //   projectId: "sunilas-alchemy",
  //   storageBucket: "sunilas-alchemy.appspot.com",
  //   messagingSenderId: "523595749962",
  //   appId: "1:523595749962:web:dd0221307a5090b7d364b3",
  //   measurementId: "G-D8952DBML8"
  // },

  firebaseConfig : {
          apiKey: "AIzaSyAoxC8vU94zEZ5GZaJwsBg-dyGyVnIFLNc",
          authDomain: "sunilasalchemy-cf09d.firebaseapp.com",
          databaseURL: "https://sunilasalchemy-cf09d.firebaseio.com",
          projectId: "sunilasalchemy-cf09d",
          storageBucket: "sunilas-alchemy.appspot.com",
          messagingSenderId: "267717305015",
          appId: "1:267717305015:web:6b77c49ece9391688fa332",
          measurementId: "G-6V9Y6BH6LQ"
        },
     

  // s3ImageBasedUrl: 'https://cricketmindsapp-dev.s3.ap-south-1.amazonaws.com/public/',
  s3ImageBasedUrl: 'https://profileletters.s3.ap-south-1.amazonaws.com/',



  stripePublicKey: "pk_test_9dyHsAzZA6VL9FSVGogiqtv4002vZ8cGuR",
  
  msg: {
    contactus: {
      confirm: "Thanks for contacting us. We will get back to you shortly."
    },
    event : {
      enrollment: {
        success: "Enrollment Success",
        fail: "Enrollment Failed"
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
