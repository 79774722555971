import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
const { PushNotifications } = Plugins;

import {
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  showSplash = false;
  constructor(
    private platform: Platform,private router: Router
  ) {
    this.initializeApp();



  }


  initializeApp() {
    // Hide the splash (you should do this on app launch)

    this.platform.ready().then(() => {
      setTimeout(() => {
          SplashScreen.hide({
            fadeOutDuration: 0
            });
        }, 3500);

      // this.statusBar.styleDefault();
    });

    PushNotifications.addListener('pushNotificationReceived',(notification:PushNotification)=>
    {
      //alert('Push received...'+JSON.stringify(notification))
    });

//       PushNotifications.addListener('pushNotificationActionPerformed',
//   (notification: PushNotificationActionPerformed) => {
//     var data = notification.notification.data;
//     //console.log('Value of data is........'  +JSON.stringify(data));
//     //alert('Push action performed: ' + JSON.stringify(notification));
//     //alert('Push action performed: ' + JSON.stringify(data));
//     alert('Push action performed: ' + "Testing click action............111");
//     //alert('Push action performed: ' + JSON.stringify(notification));
//     /**
//      *  dummy content replace with original
//      */
//     //navigate to pages by checking the data of push notification
//     // if (data.id) {
//       //this.router.navigateByUrl(`/createtest`);
      
//       this.router.navigateByUrl('/announcements');
//     // }

//     // if(notification.notification.id){
//     //   this.router.navigateByUrl(notification.notification.click_action);
//     // }
//   },

// );

//copied code
PushNotifications.addListener('pushNotificationActionPerformed',
(notification: PushNotificationActionPerformed) => {
  var data = notification.notification.data;
  console.log('Value of data is........'  +JSON.stringify(data));
  //alert('Push action performed: ' + JSON.stringify(notification));
  //alert('Push action performed: ' + JSON.stringify(data));
  /**
   *  dummy content replace with original
   */
  //navigate to pages by checking the data of push notification
  // if (data.notifId) {
    //this.router.navigateByUrl(`/createtest`);
    if(data.url!=null && data.url!=''){
    this.router.navigateByUrl(data.url);
   }
},

);

  }

//   addPushNotificationListeners() {
//   PushNotifications.addListener('pushNotificationActionPerformed',
//   (notification: PushNotificationActionPerformed) => {
//     var data = notification.notification.data;
//     console.log('Value of data is........'  +JSON.stringify(data));
//     //alert('Push action performed: ' + JSON.stringify(notification));
//     alert('Push action performed: ' + JSON.stringify(data));
//     /**
//      *  dummy content replace with original
//      */
//     //navigate to pages by checking the data of push notification
//     // if (data.notifId) {
//       //this.router.navigateByUrl(`/createtest`);
      
//       this.router.navigateByUrl('/announcements');
//     // }
//   },

// );
//   }




}
