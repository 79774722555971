import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const authRequest = request.clone({
            headers: request.headers.set('Authorization', 'Basic YWRtaW46QGRtMW5wQHNzdzByZA==')
        });

        return next.handle(authRequest);
    }
}
