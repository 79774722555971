import { ResetPassword } from './../model/resetPassword';
import { ForgotPassword } from './../model/forgotPassword';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from  '@angular/common/http';

import { Router } from '@angular/router';
import { User } from '../model/user';
import { environment } from '../../environments/environment';
import { Register } from '../model/register';
import { Login } from '../model/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem("loggedIn")) || false); 
  private currentUser = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")) || new User());
  private admin = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem("admin")) || false); 
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) { 
    
  }

  // login(userName: string, password: string, userToken: string,deviceuuid: string) {

  //   console.log('Token going to pass along login credentials..' + userToken);
  //   console.log('Deviceuid going to pass along login credentials..' + deviceuuid);

  //   // var httpHeaders = new HttpHeaders({
  //   //   'Authorization': 'Basic am9objpwYXNzd29yZA==',
  //   //   'Content-Type' : 'application/json'
  //   // });
    
    
  //   let content: Object = {'userName': userName, 'password': password, 'userToken':userToken
  // ,'deviceuuid':deviceuuid};
  //   console.log("Login details :: "+JSON.stringify(content));
  //   return this.http.put(this.apiUrl+'/login', content);
  // }

  login(userLogin:Login){
    console.log("Login details :: "+JSON.stringify(userLogin));
    return this.http.put(this.apiUrl+'/login', userLogin);
  }

  register(register: Register) {
    console.log("register :: "+JSON.stringify(register));
    return this.http.post(this.apiUrl+`/register`,  register);
  }

  forgotPassword(emailId: string) {
    console.log("forgotpassword :: "+emailId);
    let content: Object = {'emailId': emailId};

    return this.http.post(this.apiUrl+`/forgotPassword`,  content);
  }

  resetPassword(resetPassword: ResetPassword) {
      console.log("resetpassword :: "+resetPassword.password);
      let content: Object = {'memberId':resetPassword.memberId, 'password': resetPassword.password};
      return this.http.put(this.apiUrl+`/resetPassword`,  content);
    
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isAdmin() {
    return this.admin.asObservable();
  }

  save(user: User) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    localStorage.setItem("loggedIn", 'true');
    //localStorage.setItem("redirectUrlTo",'value')

    if(user.types[0].type === 'ADMIN') {
      localStorage.setItem("admin", 'true');
    } else {
      localStorage.setItem("admin", 'false');
    }

    // this.currentUser.next(user);
    this.currentUser.next(JSON.parse(localStorage.getItem("currentUser")) || new User());
    this.loggedIn.next(JSON.parse(localStorage.getItem("loggedIn")));
    this.admin.next(JSON.parse(localStorage.getItem("admin")));
  }

  getLoggedUser() {
    return this.currentUser.asObservable();
  }

  logout() {                            
    this.loggedIn.next(false);
    // localStorage.removeItem('currentUser');
    localStorage.setItem("currentUser", JSON.stringify(new User()));
    localStorage.setItem("loggedIn", 'false');
    localStorage.setItem("admin", 'false');
    localStorage.setItem("redirectUrlTo",'')
    
    this.currentUser.next( new User());
    this.router.navigate(['/']);
  }

}
