import { Subject } from 'rxjs';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/sidemenu/sidemenu.module').then( m => m.SidemenuPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },{
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'home',
    redirectTo: 'menu/home'
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'resetpassword',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'createuser',
    loadChildren: () => import('./pages/modal/createuser/createuser.module').then( m => m.CreateuserPageModule)
  },
  {
    path: 'updateuser',
    loadChildren: () => import('./pages/modal/updateuser/updateuser.module').then( m => m.UpdateuserPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'updateprofile',
    loadChildren: () => import('./pages/modal/updateprofile/updateprofile.module').then( m => m.UpdateprofilePageModule)
  },
  {
    path: 'creategroup',
    loadChildren: () => import('./pages/modal/creategroup/creategroup.module').then( m => m.CreategroupPageModule)
  },
  {
    path: 'updategroup',
    loadChildren: () => import('./pages/modal/updategroup/updategroup.module').then( m => m.UpdategroupPageModule)
  },
  {
    path: 'group-details/:groupId',
    loadChildren: () => import('./pages/group-details/group-details.module').then( m => m.GroupDetailsPageModule)
  },
  {
    path: 'assignstudents',
    loadChildren: () => import('./pages/modal/assignstudents/assignstudents.module').then( m => m.AssignstudentsPageModule)
  },
  {
    path: 'subjects',
   
    loadChildren: () => import('./pages/subjects/subjects.module').then( m => m.SubjectsPageModule)
  },
  // {
  //   path: 'subject-details/:subject',
  //   loadChildren: () => import('./pages/subject-details/subject-details.module').then( m => m.SubjectDetailsPageModule)
  // },
  {
    path: 'subject-details/:subjectId',
    loadChildren: () => import('./pages/subject-details/subject-details.module').then( m => m.SubjectDetailsPageModule)
  },
  {
    path: 'assignstosubject',
    loadChildren: () => import('./pages/modal/assignstosubject/assignstosubject.module').then( m => m.AssignstosubjectPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'createnotification',
    loadChildren: () => import('./pages/modal/createnotification/createnotification.module').then( m => m.CreatenotificationPageModule)
  },
  {
    path: 'notification-details/:notification',
    loadChildren: () => import('./pages/notification-details/notification-details.module').then( m => m.NotificationDetailsPageModule)
  },
  {
    path: 'updatenotification',
    loadChildren: () => import('./pages/modal/updatenotification/updatenotification.module').then( m => m.UpdatenotificationPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'createtest',
    loadChildren: () => import('./pages/modal/createtest/createtest.module').then( m => m.CreatetestPageModule)
  },
  {
    path: 'testdetails/:test',
    loadChildren: () => import('./pages/testdetails/testdetails.module').then( m => m.TestdetailsPageModule)
  },
  {
    path: 'updatetest',
    loadChildren: () => import('./pages/modal/updatetest/updatetest.module').then( m => m.UpdatetestPageModule)
  },
  {
    path: 'addresult',
    loadChildren: () => import('./pages/modal/addresult/addresult.module').then( m => m.AddresultPageModule)
  },
  {
    path: 'addstudentstotestt',
    loadChildren: () => import('./pages/modal/addstudentstotestt/addstudentstotestt.module').then( m => m.AddstudentstotesttPageModule)
  },
  {
    path: 'class',
    loadChildren: () => import('./pages/class/class.module').then( m => m.ClassPageModule)
  },
  {
    path: 'createclass',
    loadChildren: () => import('./pages/modal/createclass/createclass.module').then( m => m.CreateclassPageModule)
  },
  {
    path: 'class-details/:classId',
    loadChildren: () => import('./pages/classdetails/classdetails.module').then( m => m.ClassdetailsPageModule)
  },
  {
    path: 'updateclass',
    loadChildren: () => import('./pages/modal/updateclass/updateclass.module').then( m => m.UpdateclassPageModule)
  },
  {
  path: 'edittestresult',
  loadChildren: () => import('./pages/modal/edittestresult/edittestresult.module').then( m => m.EdittestresultPageModule)
},
  {
    path: 'thoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday/thoughtfortheday.module').then( m => m.ThoughtforthedayPageModule)
  },
  
  {
    path: 'createthoughtfortheday',
    loadChildren: () => import('./pages/modal/createthoughtfortheday/createthoughtfortheday.module').then( m => m.CreatethoughtforthedayPageModule)
  },
  {
    path: 'thoughtfortheday-details/:studentThoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday-details/thoughtfortheday-details.module').then( m => m.ThoughtforthedayDetailsPageModule)
  },
  {
    path: 'updatethoughtfortheday',
    loadChildren: () => import('./pages/modal/updatethoughtfortheday/updatethoughtfortheday.module').then( m => m.UpdatethoughtforthedayPageModule)
  },
  {
    path: 'achievers',
    loadChildren: () => import('./pages/achievers/achievers.module').then( m => m.AchieversPageModule)
  },
  {
    path: 'createachievers',
    loadChildren: () => import('./pages/modal/createachievers/createachievers.module').then( m => m.CreateachieversPageModule)
  },
  {
    path: 'achievers-details/:id',
    loadChildren: () => import('./pages/achievers-details/achievers-details.module').then( m => m.AchieversDetailsPageModule)
  },
  {
    path: 'updateachievers',
    loadChildren: () => import('./pages/modal/updateachievers/updateachievers.module').then( m => m.UpdateachieversPageModule)
  },
  {
    path: 'studenthome',
    loadChildren: () => import('./pages/studenthome/studenthome.module').then( m => m.StudenthomePageModule)
  },
  {
    path: 'studentnotifications',
    loadChildren: () => import('./pages/studentnotifications/studentnotifications.module').then( m => m.StudentnotificationsPageModule)
  },
  {
    path: 'updatetest',
    loadChildren: () => import('./pages/modal/updatetest/updatetest.module').then( m => m.UpdatetestPageModule)
  },
  {
    path: 'addresult',
    loadChildren: () => import('./pages/modal/addresult/addresult.module').then( m => m.AddresultPageModule)
  },
  {
    path: 'addstudentstotestt',
    loadChildren: () => import('./pages/modal/addstudentstotestt/addstudentstotestt.module').then( m => m.AddstudentstotesttPageModule)
  },
  {
    path: 'class',
    loadChildren: () => import('./pages/class/class.module').then( m => m.ClassPageModule)
  },
  {
    path: 'createclass',
    loadChildren: () => import('./pages/modal/createclass/createclass.module').then( m => m.CreateclassPageModule)
  },
  {
    path: 'class-details/:studentClass',
    loadChildren: () => import('./pages/classdetails/classdetails.module').then( m => m.ClassdetailsPageModule)
  },
  {
    path: 'updateclass',
    loadChildren: () => import('./pages/modal/updateclass/updateclass.module').then( m => m.UpdateclassPageModule)
  },
  {
  path: 'edittestresult',
  loadChildren: () => import('./pages/modal/edittestresult/edittestresult.module').then( m => m.EdittestresultPageModule)
},
  {
    path: 'thoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday/thoughtfortheday.module').then( m => m.ThoughtforthedayPageModule)
  },
  
  {
    path: 'createthoughtfortheday',
    loadChildren: () => import('./pages/modal/createthoughtfortheday/createthoughtfortheday.module').then( m => m.CreatethoughtforthedayPageModule)
  },
  {
    path: 'thoughtfortheday-details/:studentThoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday-details/thoughtfortheday-details.module').then( m => m.ThoughtforthedayDetailsPageModule)
  },
  {
    path: 'updatethoughtfortheday',
    loadChildren: () => import('./pages/modal/updatethoughtfortheday/updatethoughtfortheday.module').then( m => m.UpdatethoughtforthedayPageModule)
  },
  {
    path: 'achievers',
    loadChildren: () => import('./pages/achievers/achievers.module').then( m => m.AchieversPageModule)
  },
  {
    path: 'createachievers',
    loadChildren: () => import('./pages/modal/createachievers/createachievers.module').then( m => m.CreateachieversPageModule)
  },
  {
    path: 'achievers-details/:id',
    loadChildren: () => import('./pages/achievers-details/achievers-details.module').then( m => m.AchieversDetailsPageModule)
  },
  {
    path: 'updateachievers',
    loadChildren: () => import('./pages/modal/updateachievers/updateachievers.module').then( m => m.UpdateachieversPageModule)
  },
  {
    path: 'subjectcategory',
    loadChildren: () => import('./pages/subjectcategory/subjectcategory.module').then( m => m.SubjectcategoryPageModule)
  },
  {
    path: 'createsubjectcategory',
    loadChildren: () => import('./pages/modal/createsubjectcategory/createsubjectcategory.module').then( m => m.CreatesubjectcategoryPageModule)
  },
  {
    path: 'subcategory-details/:subjectCategoryId',
    loadChildren: () => import('./pages/subcategory-details/subcategory-details.module').then( m => m.SubcategoryDetailsPageModule)
   
  },
  {
    path: 'adminresource',
    loadChildren: () => import('./pages/adminresource/adminresource.module').then( m => m.AdminresourcePageModule)
  },
  {
    path: 'adminresourcedetails/:subjectCategoryId',
    loadChildren: () => import('./pages/adminresourcedetails/adminresourcedetails.module').then( m => m.AdminresourcedetailsPageModule)
  },
  {
    path: 'addresource',
    loadChildren: () => import('./pages/modal/addresource/addresource.module').then( m => m.AddresourcePageModule)
  },
  {
    path: 'studentchapters/:subjectCategoryId',
    loadChildren: () => import('./pages/studentchapters/studentchapters.module').then( m => m.StudentchaptersPageModule)
  },
  // {
  //   path: 'studentresources/:chapterId',
  //   loadChildren: () => import('./pages/studentresources/studentresources.module').then( m => m.StudentresourcesPageModule)
  // },
  {
    path: 'studentresources/:chapterId/resourceType/:resourceType',
    loadChildren: () => import('./pages/studentresources/studentresources.module').then( m => m.StudentresourcesPageModule)
  },
  {
    path: 'adminchapter/:subjectCategoryId',
    loadChildren: () => import('./pages/adminchapter/adminchapter.module').then( m => m.AdminchapterPageModule)
  },
  {
    path: 'createchapter',
    loadChildren: () => import('./pages/modal/createchapter/createchapter.module').then( m => m.CreatechapterPageModule)
  },
  {
    path: 'chapterresource/subjectcategory/:subjectCategoryId/chapter/:chapterId',
    loadChildren: () => import('./pages/chapterresource/chapterresource.module').then( m => m.ChapterresourcePageModule)
  },
  {
    path: 'createresource',
    loadChildren: () => import('./pages/modal/createresource/createresource.module').then( m => m.CreateresourcePageModule)
  },
  {
    path: 'mytest',
    loadChildren: () => import('./pages/mytest/mytest.module').then( m => m.MytestPageModule)
  },
  {
    path: 'menu/coursesoffered',
    loadChildren: () => import('./pages/coursesoffered/coursesoffered.module').then( m => m.CoursesofferedPageModule)
  },
  {
    path: 'viewresults',
    loadChildren: () => import('./pages/viewresults/viewresults.module').then( m => m.ViewresultsPageModule)
  },
  {
    path: 'myresources/:chapterId',
    loadChildren: () => import('./pages/myresources/myresources.module').then( m => m.MyresourcesPageModule)
  },
  {
    path: 'updateresource',
    loadChildren: () => import('./pages/modal/updateresource/updateresource.module').then( m => m.UpdateresourcePageModule)
  },
  {
    path: 'resourcedetails/:resourceId',
    loadChildren: () => import('./pages/resourcedetails/resourcedetails.module').then( m => m.ResourcedetailsPageModule)
  },
  {
    path: 'topachievers',
    loadChildren: () => import('./pages/topachievers/topachievers.module').then( m => m.TopachieversPageModule)
  },
  {
    path: 'updatechapter',
    loadChildren: () => import('./pages/modal/updatechapter/updatechapter.module').then( m => m.UpdatechapterPageModule)
  },
  {
    path: 'announcements',
    loadChildren: () => import('./pages/announcements/announcements.module').then( m => m.AnnouncementsPageModule)
  },
  {
    path: 'allsubjects',
    loadChildren: () => import('./pages/allsubjects/allsubjects.module').then( m => m.AllsubjectsPageModule)
  },

  {
    path: 'currentsubject/:subjectId',
    loadChildren: () => import('./pages/currentsubject/currentsubject.module').then( m => m.CurrentsubjectPageModule)
  },
  {
    path: 'enroll/:sub',
    loadChildren: () => import('./pages/enroll/enroll.module').then( m => m.EnrollPageModule)
  },
  {
    path: 'findingenrollments',
    loadChildren: () => import('./pages/findingenrollments/findingenrollments.module').then( m => m.FindingenrollmentsPageModule)
  },
  {
    path: 'class',
    loadChildren: () => import('./pages/class/class.module').then( m => m.ClassPageModule)
  },
  {
    path: 'createclass',
    loadChildren: () => import('./pages/modal/createclass/createclass.module').then( m => m.CreateclassPageModule)
  },
  {
    path: 'class-details/:studentClass',
    loadChildren: () => import('./pages/classdetails/classdetails.module').then( m => m.ClassdetailsPageModule)
  },
  {
    path: 'updateclass',
    loadChildren: () => import('./pages/modal/updateclass/updateclass.module').then( m => m.UpdateclassPageModule)
  },
  {
  path: 'edittestresult',
  loadChildren: () => import('./pages/modal/edittestresult/edittestresult.module').then( m => m.EdittestresultPageModule)
},
  {
    path: 'thoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday/thoughtfortheday.module').then( m => m.ThoughtforthedayPageModule)
  },
  
  {
    path: 'createthoughtfortheday',
    loadChildren: () => import('./pages/modal/createthoughtfortheday/createthoughtfortheday.module').then( m => m.CreatethoughtforthedayPageModule)
  },
  {
    path: 'thoughtfortheday-details/:studentThoughtfortheday',
    loadChildren: () => import('./pages/thoughtfortheday-details/thoughtfortheday-details.module').then( m => m.ThoughtforthedayDetailsPageModule)
  },
  {
    path: 'updatethoughtfortheday',
    loadChildren: () => import('./pages/modal/updatethoughtfortheday/updatethoughtfortheday.module').then( m => m.UpdatethoughtforthedayPageModule)
  },
  {
    path: 'achievers',
    loadChildren: () => import('./pages/achievers/achievers.module').then( m => m.AchieversPageModule)
  },
  {
    path: 'createachievers',
    loadChildren: () => import('./pages/modal/createachievers/createachievers.module').then( m => m.CreateachieversPageModule)
  },
  {
    path: 'achievers-details/:id',
    loadChildren: () => import('./pages/achievers-details/achievers-details.module').then( m => m.AchieversDetailsPageModule)
  },
  {
    path: 'updateachievers',
    loadChildren: () => import('./pages/modal/updateachievers/updateachievers.module').then( m => m.UpdateachieversPageModule)
  },
  {
    path: 'subjectcategory',
    loadChildren: () => import('./pages/subjectcategory/subjectcategory.module').then( m => m.SubjectcategoryPageModule)
  },
  {
    path: 'createsubjectcategory',
    loadChildren: () => import('./pages/modal/createsubjectcategory/createsubjectcategory.module').then( m => m.CreatesubjectcategoryPageModule)
  },
  {
    path: 'subcategory-details/:subjectCategoryId',
    loadChildren: () => import('./pages/subcategory-details/subcategory-details.module').then( m => m.SubcategoryDetailsPageModule)
   
  },
  {
    path: 'adminresource',
    loadChildren: () => import('./pages/adminresource/adminresource.module').then( m => m.AdminresourcePageModule)
  },
  {
    path: 'adminresourcedetails/:subjectCategoryId',
    loadChildren: () => import('./pages/adminresourcedetails/adminresourcedetails.module').then( m => m.AdminresourcedetailsPageModule)
  },
  {
    path: 'addresource',
    loadChildren: () => import('./pages/modal/addresource/addresource.module').then( m => m.AddresourcePageModule)
  },
  {
    path: 'studentchapters/:subjectCategoryId',
    loadChildren: () => import('./pages/studentchapters/studentchapters.module').then( m => m.StudentchaptersPageModule)
  },
  // {
  //   path: 'studentresources/:chapterId',
  //   loadChildren: () => import('./pages/studentresources/studentresources.module').then( m => m.StudentresourcesPageModule)
  // },
  {
    path: 'studentresources/:chapterId/resourceType/:resourceType',
    loadChildren: () => import('./pages/studentresources/studentresources.module').then( m => m.StudentresourcesPageModule)
  },
  {
    path: 'adminchapter/:subjectCategoryId',
    loadChildren: () => import('./pages/adminchapter/adminchapter.module').then( m => m.AdminchapterPageModule)
  },
  {
    path: 'createchapter',
    loadChildren: () => import('./pages/modal/createchapter/createchapter.module').then( m => m.CreatechapterPageModule)
  },
  {
    path: 'chapterresource/subjectcategory/:subjectCategoryId/chapter/:chapterId',
    loadChildren: () => import('./pages/chapterresource/chapterresource.module').then( m => m.ChapterresourcePageModule)
  },
  {
    path: 'createresource',
    loadChildren: () => import('./pages/modal/createresource/createresource.module').then( m => m.CreateresourcePageModule)
  },
  {
    path: 'mytest',
    loadChildren: () => import('./pages/mytest/mytest.module').then( m => m.MytestPageModule)
  },
  {
    path: 'menu/coursesoffered',
    loadChildren: () => import('./pages/coursesoffered/coursesoffered.module').then( m => m.CoursesofferedPageModule)
  },
  {
    path: 'viewresults',
    loadChildren: () => import('./pages/viewresults/viewresults.module').then( m => m.ViewresultsPageModule)
  },
  {
    path: 'myresources/:chapterId',
    loadChildren: () => import('./pages/myresources/myresources.module').then( m => m.MyresourcesPageModule)
  },
  {
    path: 'updateresource',
    loadChildren: () => import('./pages/modal/updateresource/updateresource.module').then( m => m.UpdateresourcePageModule)
  },
  {
    path: 'resourcedetails/:resourceId',
    loadChildren: () => import('./pages/resourcedetails/resourcedetails.module').then( m => m.ResourcedetailsPageModule)
  },
  {
    path: 'topachievers',
    loadChildren: () => import('./pages/topachievers/topachievers.module').then( m => m.TopachieversPageModule)
  },
  {
    path: 'updatechapter',
    loadChildren: () => import('./pages/modal/updatechapter/updatechapter.module').then( m => m.UpdatechapterPageModule)
  },
  {
    path: 'announcements',
    loadChildren: () => import('./pages/announcements/announcements.module').then( m => m.AnnouncementsPageModule)
  },
  {
    path: 'allsubjects',
    loadChildren: () => import('./pages/allsubjects/allsubjects.module').then( m => m.AllsubjectsPageModule)
  },

  {
    path: 'currentsubject/:subjectId',
    loadChildren: () => import('./pages/currentsubject/currentsubject.module').then( m => m.CurrentsubjectPageModule)
  },
  {
    path: 'enroll/:sub',
    loadChildren: () => import('./pages/enroll/enroll.module').then( m => m.EnrollPageModule)
  },
  {
    path: 'findingenrollments',
    loadChildren: () => import('./pages/findingenrollments/findingenrollments.module').then( m => m.FindingenrollmentsPageModule)
  },
 
  {
    path: 'managepayment',
    loadChildren: () => import('./pages/modal/managepayment/managepayment.module').then( m => m.ManagepaymentPageModule)
  },

  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'userclass',
    loadChildren: () => import('./pages/userclass/userclass.module').then( m => m.UserclassPageModule)
  },
  {
    path: 'usersubject/:classId',
    loadChildren: () => import('./pages/usersubject/usersubject.module').then( m => m.UsersubjectPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
